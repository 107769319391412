"use client"
import React from 'react'
import Image from 'next/image'
import { Card } from '../ui/card'
import ApexAchievements from './apex-achievements'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



function WhyApex(props) {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className='custom-container lg:mb-20 mb-10'>
            <h2 className='header1 text-primaryBlue md:text-left text-center mt-2 mb-5'>{props.whyApex.why}</h2>
            <div className='flex flex-col lg:flex-row mt-6 gap-4 justify-center'>
                <div className='md:block w-full hidden md:mx-auto lg:mx-0 rounded-3xl overflow-hidden'>
                    <div className='grid md:grid-cols-2 gap-4'>
                        {
                        props.data?.data?.map((item, i) => (
                        <Card className='py-3 px-3 w-full md:max-w-72 rounded-3xl border-primaryBlue' key={i}>
                            <Image className='w-full h-auto max-h-60 max-w-md object-cover object-center rounded-2xl' src={`${process.env.NEXT_PUBLIC_BE_URL}${item?.attributes?.whyChooseApexImage.data?.attributes?.url}`} width={367} height={224} alt={item?.attributes?.whyChooseApexTitle} title={item?.attributes?.whyChooseApexTitle} />
                            <p className='p-text pt-3 text-center  text-primaryGreyText font-light'>{item?.attributes?.whyChooseApexTitle}</p>
                        </Card>
                        ))
                        }
                    </div>
                </div>
                
                {/* Mobile view */}
                <div className="relative block md:hidden">
                <Slider {...settings} className='relative'>
                    { props.data?.data?.map((items, i) => (
                        <Card className='p-2 w-full max-w-lg rounded-2xl border-primaryBlue' key={i}>
                            <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${items?.attributes?.whyChooseApexImage.data?.attributes?.url}`} className='relative w-full h-full max-h-56 object-cover object-center mx-auto rounded-2xl' width={367} height={251} alt='apex-hospitals' title='apex-hospitals' />
                            <p className='text-lg md:text-xl text-primaryBlack px-2 py-2 text-center '>{items?.attributes?.whyChooseApexTitle}</p>
                        </Card>
                    ))}
                </Slider>
                </div>

                <ApexAchievements data={props.pointer}/>
            </div>
        </div>
        
    )
}

export default WhyApex;
