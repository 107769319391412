import Image from 'next/image'


const achievementsData = [
    {
        images: '/locations-images/year-of-excellence.png',
        img: '/locations-images/year-of-excellence.webp',
        year: '30+',
        desc: 'Year of Excellence'
    },
    {
        images: '/locations-images/million-lives.png',
        img: '/locations-images/million-lives.webp',
        year: '20+',
        desc: 'Million Lives Touched'
    }, {
        images: '/locations-images/doctor.png',
        img: '/locations-images/doctor.webp',
        year: '100+',
        desc: 'Doctors'
    }, {
        images: '/locations-images/specialities.png',
        img: '/locations-images/specialities.webp',
        year: '36+',
        desc: 'Specialities'
    },
    {
        images: '/locations-images/hospital.png',
        img: '/locations-images/hospital.webp',
        year: '5+',
        desc: 'Hospitals'
    }
]

function ApexAchievements(props) {

    return (
        <div className='max-w-xs w-full bg-primaryBlue rounded-2xl mx-auto lg:mx-0 pt-5 pb-5 mt-8 md:mt-0 flex flex-col items-start justify-evenly'>
            {props.data?.data?.map((item, i) => {
                const titleParts = item?.attributes.PointerTitle.split(' ');
                const numberPart = titleParts[0];
                const stringPart = titleParts.slice(1).join(' ');

                return (
                    <div className='px-4 py-3 flex flex-row gap-3 w-full' key={i}>
                        <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${item?.attributes.pointerIcon.data.attributes.url}`} className='relative w-full h-full object-cover object-center mx-auto basis-1/5' width={60} height={60} alt='achivements' />
                        <div className='basis-4/5 w-full'>
                            <h4 className='text-gray-100 font-bold text-xl xl:text-2xl'>{numberPart}</h4>
                            <p className='text-gray-100 text-base xl:text-lg'>{stringPart}</p>
                        </div>
                    </div>
                );
            })}
        </div>

    )
}

export default ApexAchievements