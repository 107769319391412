
'use client'

import React from 'react';
import Slider from 'react-slick';
import Image from 'next/image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



function Achievements(props) {
    
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => (
            <div>
                <ul className="custom-dots">{dots}</ul>
            </div>
        ),
    };

    return (
        <div className='bg-primaryBlack py-10 lg:mb-20 mb-10'>
            <h2 className='custom-container header1 text-primaryYellow text-center mt-2 md-5'>{props.achieve.title}</h2>
            
            {/* Desktop view */}
            <div className='custom-container mt-6 hidden sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
                {props.data?.data?.map((items, i) => (
                    <div key={i} className='flex flex-col justify-start items-center gap-2 sm:gap-4'>
                        <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${items?.attributes?.achievementIcon.data?.attributes?.url}` } width={48} height={49} className='mt-5' alt='activity' title='activity' />
                        <p className='text-center text-secondaryGrey p-text font-light leading-7'>{items?.attributes?.achievementTitle}</p>
                    </div>
                ))}
            </div>

            <div className='relative block sm:hidden custom-container mt-6'>
                {/* Mobile view */}
                <Slider {...settings}>
                    {props.data?.data?.map((items, i) => (
                        <div key={i} className='flex flex-col justify-center'>
                            <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${items?.attributes?.achievementIcon.data?.attributes?.url}` } width={32} height={32} className='mx-auto' alt='activity' title='activity' />
                            <p className='font-family-roboto  text-secondaryGrey text-base mt-4 w-64 text-center mx-auto'>{items?.attributes?.achievementTitle}</p>
                        </div>
                    ))}
                </Slider>
            </div>

        </div>
     
    );
}

export default Achievements;


