'use client'
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image from "next/image";
import leftarrow from '@/public/images/about-us/left-arrow.png'
import rightarrow from '@/public/images/about-us/right-arrow.png'
import CustomLink from '../common/custom-link';





function CentersOfExcellence (props) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1164,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 798,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                }
            }
        ]
    };

    const handleNext = () => {
        slider.slickNext();
    };

    const handlePrevious = () => {
        slider.slickPrev();
    };

    let slider;

    return (
        <div className='carousal-container lg:mb-20 mb-10'>
            <h2 className='custom-container header1 text-primaryGreen text-center mt-2 mb-5 normal-case'>{props.centre.title}</h2>
            <Slider {...settings} ref={(c) => (slider = c)} className='custom-container relative box-border'>
               { props.data?.data?.map((e,i)=>(
               <div className='coe-flex w-auto lg:w-72' key={i}>
                    <div className="w-72 group hover:p-6 inline-block mx-auto px-16 sm:mx-2 py-24 hover:bg-primaryBlue rounded-3xl hover:border hover:border-gray-300 relative font-roboto transition-colors ease-out-in duration-700 h-96">
                        <div className="p-9 group-hover:p-0 group-hover:w-20 group-hover:h-20 group-hover:ml-0 group-hover:rounded-full overflow-hidden">
                            <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${e.attributes?.centresOfExcellenceIcon.data?.attributes?.url}`} alt="" width={89} height={89}
                                className="group-hover:-translate-x-0 group-hover:-translate-y-0 group-hover:transform group-hover:bg-primaryWhite duration-700 transition-all ease-in-out group-hover:border-0 group-hover:border-primaryBlack group-hover:w-full group-hover:h-full group-hover:p-[10px] group-hover:object-contain group-hover:object-center" />
                        </div>
                        <div className='inline-block text-center capitalize duration-700 transition ease-in-out w-full group-hover:text-left group-hover:translate-x-20 group-hover:-translate-y-20 group-hover:transform group-hover:w-full group-hover:max-w-[150px] group-hover:ml-2 group-hover:h-full group-hover:max-h-[100px]'>
                            <p className="text-center group-hover:text-left group-hover:break-words font-semibold text-xl leading-7 group-hover:text-primaryWhite text-ellipsis line-clamp-3 group-hover:w-full overflow-hidden group-hover:h-auto normal-case">{e.attributes?.centresOfExcellenceName.replace('Icu','ICU').replace('And','and')}</p>
                        </div>
                        <div className="absolute top-32 left-0 px-4 hidden group-hover:block group-hover:text-primaryWhite">
                            <div className="relative h-40 overflow-y-scroll mb-5">
                                <p className='p-text text-primaryWhite mb-3 text-left'>{e.attributes?.centreOfExcellenceShortDescription}</p>
                                <ul className="list-disc pl-5 pr-2">
                                  {e.attributes?.centreOfExcellenceDescription.split("\n").map((el,ei)=>(<li className='p-text text-primaryWhite mb-3 text-left' key={ei}>{el.replace('-','')}</li>))}
                                </ul>
                            </div>
                            <CustomLink lang={props.lang} href={`/centre-of-excellence/${e.attributes.coe_tag?.toLowerCase().replaceAll(/\s/g, '-')}`} className="py-2 px-4 text-sm float-right bg-inherit border hover:bg-primaryWhite hover:text-primaryBlue border-primaryWhite uppercase rounded-full mr-4 coe-knowmore" >{props.centre.knowMore}</CustomLink>
                        </div>
                    </div>
                </div>))}
            </Slider>
            <div className='flex justify-between relative'>
                <button className="rounded-full border border-primaryGrey py-3 px-4 z-10 hidden lg:absolute lg:bottom-40 xl:left-12 lg:left-4 lg:inline-block" onClick={handlePrevious}>
                    <Image src={leftarrow} height={24} width={12} alt="left-arrow-button" />
                </button>
                <button className="rounded-full border border-primaryGrey py-3 px-4 z-10 hidden lg:absolute lg:bottom-40 xl:right-12 lg:right-4 lg:inline-block" onClick={handleNext}>
                    <Image src={rightarrow} height={24} width={12} alt="right-arrow-button" />
                </button>
            </div>
        </div>
    );
};

export default CentersOfExcellence;
